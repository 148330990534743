import { useFetcher } from '@remix-run/react'
import { useMapsLibrary } from '@vis.gl/react-google-maps'
import { getBoundsOfDistance, getCenter, getDistance } from 'geolib'
import { useCallback, useState } from 'react'
import { loader as spotsLoader } from '~/routes/spots'

export function useSpotsQuery() {
  const corelib = useMapsLibrary('core')
  const [previousBbox, setPreviousBbox] = useState<google.maps.LatLngBounds | undefined>(undefined)
  const fetcher = useFetcher<typeof spotsLoader>()

  const reloadFunc = useCallback(
    (_cache = true, bbox?: number[]) => {
      fetcher.load(`/spots?bbox=${bbox?.join(',')}`)
    },
    [fetcher],
  )

  const loading = fetcher.state !== 'idle'
  const data = fetcher.data

  const reloadFuncWithBbox = useCallback(
    (cache: boolean, bounds?: google.maps.LatLngBounds | undefined, mag: number = 1.2) => {
      if (!corelib) return
      if (!bounds) return reloadFunc(cache)

      const ne = bounds.getNorthEast()
      const sw = bounds.getSouthWest()
      const center = getCenter([
        { latitude: sw.lat(), longitude: sw.lng() },
        { latitude: ne.lat(), longitude: ne.lng() },
      ])
      if (!center) return

      // mag倍の広さを取得
      const distance = getDistance(
        { latitude: sw.lat(), longitude: sw.lng() },
        { latitude: ne.lat(), longitude: ne.lng() },
      )
      const expandedBounds = getBoundsOfDistance(center, distance * mag)
      const bbox = [
        expandedBounds[0].latitude,
        expandedBounds[0].longitude,
        expandedBounds[1].latitude,
        expandedBounds[1].longitude,
      ]
      const exSw = { lat: bbox[0], lng: bbox[1] }
      const exNe = { lat: bbox[2], lng: bbox[3] }
      reloadFunc(cache, bbox)
      setPreviousBbox(() => new corelib.LatLngBounds(exSw, exNe))
    },
    [corelib, reloadFunc, setPreviousBbox],
  )

  return { reloadFuncWithBbox, loading, data, previousBbox }
}
