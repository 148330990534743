import { CollisionBehavior, useMap } from '@vis.gl/react-google-maps'
import { Cluster, type Marker, MarkerClusterer, MarkerUtils } from '@googlemaps/markerclusterer'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { SpotMarker } from './SpotMarker'
import { ClusterMarkerRenderer } from './ClusterMarkerRenderer'
import { expandBounds } from '~/utils/geoutil'
import { useNavigate } from '@remix-run/react'
import { SpotsQueryQuery } from '~/graphql/generated/graphql'

export function ClusteredSpotMarkers({
  spots,
  showTitle,
  currentBounds,
}: {
  spots: SpotsQueryQuery['spots']
  showTitle: boolean
  currentBounds: google.maps.LatLngBounds | null
}) {
  const [markers, setMarkers] = useState<{ [key: string]: Marker }>({})
  const map = useMap()
  const navigate = useNavigate()
  const clusterer = useMemo(() => {
    const algorithmOptions = { maxZoom: 22, radius: 100 }
    if (!map) return
    const renderer = new ClusterMarkerRenderer(navigate)
    const onClusterClick = (
      _: google.maps.MapMouseEvent,
      cluster: Cluster,
      map: google.maps.Map,
    ) => {
      if (!cluster.bounds) return
      map.fitBounds(expandBounds(cluster.bounds, 0.0001))
    }
    return new MarkerClusterer({ map, renderer, algorithmOptions, onClusterClick })
  }, [map])

  const setMarkerRef = useCallback((marker: Marker | null, key: string) => {
    setMarkers((markers) => {
      if ((marker && markers[key]) || (!marker && !markers[key])) return markers
      if (marker) {
        return { ...markers, [key]: marker }
      } else {
        const { [key]: _, ...rest } = markers
        return rest
      }
    })
  }, [])

  useEffect(() => {
    if (!clusterer) return
    if (!currentBounds) return

    clusterer.clearMarkers(false)
    clusterer.addMarkers(
      Object.values(markers).filter((marker) =>
        expandBounds(currentBounds)?.contains(MarkerUtils.getPosition(marker)),
      ),
      false,
    )
    clusterer.render()
    return () => {
      // reloadされたタイミングで古いものが残ってしまうのでマーカを消しておく
      clusterer.clearMarkers(false)
    }
  }, [clusterer, markers, currentBounds])

  return (
    <>
      {spots.map((spot, index) => {
        return (
          <SpotMarker
            key={index}
            spot={spot}
            collisionBehavior={CollisionBehavior.REQUIRED_AND_HIDES_OPTIONAL}
            showTitle={showTitle}
            setMarkerRef={setMarkerRef}
          />
        )
      })}
    </>
  )
}
