import { AdvancedMarker, CollisionBehavior, useAdvancedMarkerRef } from '@vis.gl/react-google-maps'
import { SpotHolder, SpotHolderLabel } from '../SpotHolder'
import { Marker } from '@googlemaps/markerclusterer'
import { useEffect } from 'react'
import { useNavigate, useParams } from '@remix-run/react'
import { SpotsItemFragment } from '~/graphql/generated/graphql'

type Props = React.PropsWithChildren<{
  spot: SpotsItemFragment
  showTitle: boolean
  collisionBehavior: CollisionBehavior | undefined
  setMarkerRef: (marker: Marker | null, key: string) => void
}>

export function SpotMarker({ spot, showTitle, collisionBehavior, setMarkerRef }: Props) {
  const { spotCode } = useParams()
  const navigate = useNavigate()
  const [markerRef, marker] = useAdvancedMarkerRef()

  const onClick = () => {
    const code = spot?.code
    navigate(`/spots/${code}`, { replace: true })
  }

  useEffect(() => {
    // marker にユーザデータをもたせる苦肉の策
    if (marker?.content) {
      const content = marker.content as HTMLElement
      content.dataset.imageUrl = spot.firstSpotPhoto?.imageUrl ?? ''
      content.dataset.code = spot.code
      content.dataset.crowdedLevel = spot.isOpen ? spot.crowdedLevel : 'noBusinessHours'
      content.dataset.name = spot.name
    }
    setMarkerRef(marker, spot.code)
  }, [setMarkerRef, marker])

  const position = { lat: spot.latitude ?? 0, lng: spot.longitude ?? 0 }

  const highlight = spotCode == spot.code

  if (!spot.latitude && !spot.longitude) return

  return (
    <>
      <AdvancedMarker
        className="relative"
        position={position}
        collisionBehavior={collisionBehavior}
        title={spot.name}
        onClick={onClick}
        ref={markerRef}
        zIndex={highlight ? 10 : 1}
      >
        <SpotHolder
          crowdedLevel={spot.isOpen ? spot.crowdedLevel : 'noBusinessHours'}
          imageUrl={spot.firstSpotPhoto?.thumbnailImageUrl ?? ''}
          highlight={highlight}
        />
        {showTitle && <SpotHolderLabel text={spot.name} highlight={highlight} />}
      </AdvancedMarker>
    </>
  )
}
