import { ClientOnly } from 'remix-utils/client-only'
import { Outlet, useOutletContext, useParams, useSearchParams } from '@remix-run/react'
import { Header } from '~/components/Header'
import { ToastContainer } from 'react-toastify'
import { firebaseAnalytics } from '~/utils/firebase.client'
import { logEvent } from 'firebase/analytics'
import { MapContainer } from '~/components/MapContainer'
import { Latlng } from '~/models/PositionType'
import { useState } from 'react'
import { UserType } from '~/models/UserType'

export default function Maps() {
  const { spotCode } = useParams()
  const userdata = useOutletContext<UserType>()
  const [searchParams] = useSearchParams()
  const [position, setPosition] = useState<Latlng | undefined>()
  const [currentSpotPosition, setCurrentSpotPosition] = useState<Latlng | undefined>()
  const [full, setFull] = useState(false)
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        closeOnClick
        theme="light"
        hideProgressBar
      />
      <Header />
      <div
        className={`absolute md:w-96 md:h-full md:top-10 md:rounded-r-lg md:shadow-xl
                    w-screen h-screen 
                    ${
                      spotCode
                        ? full
                          ? 'translate-y-10 md:translate-x-0'
                          : 'translate-y-1/2 md:translate-x-0'
                        : 'translate-y-full md:-translate-x-full'
                    }
                    duration-300 transition md:translate-y-0
                    left-0 bottom-0 bg-white delay-75 z-10 rounded-t-lg overflow-hidden`}
      >
        <div className={`overflow-y-auto ${full ? 'h-full' : 'h-1/2 md:h-full'}`}>
          <Outlet context={{ position, setFull, setCurrentSpotPosition }} />
        </div>
      </div>
      <ClientOnly>
        {() => (
          <MapContainer
            userdata={userdata}
            setPosition={setPosition}
            currentSpotPosition={currentSpotPosition}
            defaultCenter={searchParams.get('c')}
            defaultZoom={parseFloat(searchParams.get('z') ?? '18')}
            onReloadCurrentTime={() => logEvent(firebaseAnalytics, 'reload_current_time', {})}
          ></MapContainer>
        )}
      </ClientOnly>
    </>
  )
}
